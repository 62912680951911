.a-box {
  display: inline-block;
  width: 240px;
  text-align: center;
  padding: 15px;
  font-family: "Roboto";
}

.img-container {
  height: 200px;
  width: 200px;
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  display: inline-block;
}

.img-container img {
  height: 170px;
  position: relative;
  border-radius: 20px;
}

.position {
  display: block;
  padding-top: 30px;
  font-size: 20px;
}

.team-name-image {
  width: 200px;
  display: block;
  margin: 0 auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.inner-skew {
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  font-size: 0px;
  margin: 30px 0px 0px 0px;
  height: 260px;
  width: 200px;
}

.a-box-artist {
  background: rgba(120, 120, 120, 0.35);
  display: inline-block;
  width: 1050px;
  height: auto;
  text-align: left;
  font-family: "Roboto";
  border-radius: 20px;

  @media (max-width: 1100px) {
    width: 240px;
    align-items: center;
    text-align: center;
    height: 600px;
  }

  @media (max-width: 500px) {
    height: 650px;
  }

  @media (max-width: 786px) {
    width: 240px;
    height: 600px;
  }
}

.artist-image {
  height: 170px;
  position: relative;
  border-radius: 20px;
}

.inner-skew-artist {
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  font-size: 0px;
  margin: 30px 0px 0px 0px;
  height: 260px;
  width: 200px;
}

.img-container-artist {
  position: relative;
  top: -25px;
  left: 32px;
  width: 200px;
  height: 200px;
  display: inline-block;
  float: left;
  @media (max-width: 1100px) {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    float: none;
    width: 100%;
    left: 0px;
  }
}

.text-container-artist {
  position: relative;
  display: inline-block;
  width: 70%;
  height: 50px;
  float: right;
  font-family: "Roboto";

  @media (max-width: 1100px) {
    display: block;
    float: none;
    width: 100%;
  }
}

.text-container-artist-header {
  height: auto;
  font-size: 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: "Roboto";

  @media (max-width: 1100px) {
    display: block;
  }
}

.text-container-artist-header span {
  padding-left: 10px;
  font-size: 20px;
  @media (max-width: 1100px) {
    display: block;
  }
}

.text-container-artist-paragraph {
  padding: 15px;
  font-family: "Roboto";
  font-size: 15px;
  @media (max-width: 1100px) {
    display: block;
  }
}

.text-container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 120px 20px 20px 20px;
  border-radius: 20px;
  background: rgba(120, 120, 120, 0.35);
  margin: -120px 0px 0px 0px;
  line-height: 19px;
  font-size: 15px;
}

.text-container span {
  display: block;
  font-family: "Roboto";
  white-space: break-space;
  z-index: 10;
}

.text-container h3 {
  margin: 20px 0px 10px 0px;
  color: #04bcff;
  font-size: 17px;
}
