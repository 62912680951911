/* Popup style */
.popup-box {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 3;
  left: 0;
}

.popup-header {
  display: block;
  margin: 0 auto;
}

.popup-exit {
  cursor: pointer;
  position: fixed;
  right: 15px;
  top: 15px;
  width: 15px;
  height: 15px;
  font-weight: bold;
  line-height: 20px;
}

.box {
  position: relative;
  margin: 0 auto;
  margin-top: calc(100vh - 80vh - 20px);
  backdrop-filter: blur(100px) contrast(70%);
  -webkit-backdrop-filter: blur(100px) contrast(70%);
  background: black;
  border-radius: 20px;
  color: white;
  font-family: "Roboto";
  overflow: auto;
  animation: fade 0.5s ease-in both;
  opacity: 0;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  width: 60vw;
  height: 60vh;
  z-index: 100;
  @media screen and (max-width: 60em) {
    grid-template-rows: repeat(4, 1fr);
    margin-top: calc(100vh - 86vh - 20px);
    width: 85vw;
    height: 85vh;
  }
}

.box-mobile {
  position: relative;
  margin: 0 auto;
  margin-top: calc(100vh - 80vh - 20px);
  backdrop-filter: blur(100px) contrast(70%);
  -webkit-backdrop-filter: blur(100px) contrast(70%);
  background: black;
  border-radius: 20px;
  color: white;
  font-family: "Roboto";
  overflow: auto;
  animation: fade 0.5s ease-in both;
  opacity: 0;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(1, 1fr);
  width: 60vw;
  height: 30vh;
  z-index: 100;
  @media screen and (max-width: 60em) {
    grid-template-rows: repeat(4, 1fr);
    margin-top: calc(100vh - 75vh - 20px);
    /*     margin-top: calc(100vh - 86vh - 20px);
 */
    width: 85vw;
    height: 35vh;
  }
}

.wallet-image {
  width: 75px;
  height: 75px;
}

.connect-type {
  margin: 30px;
}

.grid-style {
  text-align: center;
  border: solid rgba(128, 128, 128, 0.2) 1px;
}

.grid-style:hover {
  -moz-box-shadow: inset 0 0 10px grey;
  -webkit-box-shadow: inset 0 0 10px grey;
  box-shadow: inset 0 0 10px grey;
  cursor: pointer;
}

.grid-1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 1;
  @media screen and (max-width: 60em) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row: 1;
  }
}

.grid-2 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 1;
  @media screen and (max-width: 60em) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row: 2;
  }
}

.grid-all {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row: 1;
  @media screen and (max-width: 60em) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row: 1;
  }
}

/* .grid-3 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 2;
  @media screen and (max-width: 60em) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row: 3;
  }
} */

.grid-4 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row: 2;
  @media screen and (max-width: 60em) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row: 4;
  }
}

.callout {
  margin: 0;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
