.footer {
	width: 100%;
	background: black;
	display: block;
 }

 .footer-logo{
	 width: 330px;
	 padding-top: 50px;
	 @media only screen and (max-width: 960px) {
		width: 200px;
		padding-top: 25px;
	  }
 }

 .inner-footer {
	 width: 95%;
	 margin: auto;
	 padding: 30px 10px;
	 display: flex;
	 flex-wrap: wrap;
	 box-sizing: border-box;
	 justify-content: center;
 }

.footer-items {
	width: 30%;
  text-align: left;
	padding: 10px 20px;
	box-sizing: border-box;
	font-family: "Roboto";
	color: #fff;
  @media only screen and (max-width: 960px) {
    text-align: left;
  }
}

.footer-items p {
	font-size: 17px;
	text-align: justify;
	line-height: 25px;
	color: #fff;
}

.links-footer{
  display: inline-block;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.footer-items h1 {
	color: #fff;
}

.border1 {
	height: 3px;
	width: 40px;
	background: #83111a;
	color: #83111a;
	background-color: #83111a;
	border: 0px;
}

.footer ul {
	list-style: none;
	color: #fff;
	font-size: 17px;
	letter-spacing: 0.5px;	
 }

 .footer ul a {
	text-decoration: none;
	outline: none;
	color: #fff;
	transition: 0.3s;
  cursor: pointer;
}

.footer ul a:hover {
	color: #83111a;
}

.footer ul li {
	margin: 10px 0;
	height: 25px;
}

.footer li i {
	margin-right: 20px;
}

.social-media {
	width: 100%;
	color: #fff;
	text-align: right;
	font-size: 17px;
}

.social-media a {
	text-decoration: none;
}

.social-media i {
	height: 25px;
	width: 25px;
	margin: 20px 10px;
	padding: 4px;
	color: #fff;
	transition: 0.5s;
}

.social-media i:hover {
	transform: scale(1.5);
}

.footer-bottom {
	padding: 10px;
	background: black;
	color: #fff;
	font-size: 12px;
	text-align: center;
}

/* for tablet mode view */

@media screen and (max-width: 1275px) {
	.footer-items {
		width: 50%;
	}
}

/* for mobile screen view */

@media screen and (max-width: 660px) {
	.footer-items {
		width: 100%;
	}
}
