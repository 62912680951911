.text-menu {
  font-size: 1.3rem;
}

.navbar-container {
  top: 0px;
  width: 100%;
  z-index: 15;
  height: 105px;
  position: fixed;
  background-color: black;
  mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
  animation: fadeIn 1.2s ease-in both;
  opacity: 0;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.nav-menu {
  position: fixed;
  width: 100%;
  left: 250px;
  opacity: 1;
  list-style: none;
  font-weight: bold;
  font-family: "Roboto";
  line-height: 55px;
}

.logo {
  position: absolute;
  width: 236px;
  height: 43px;
  top: 20px;
  left: 30px;
}

.nav-menu li {
  display: inline-block;
  padding-left: 20px;
  padding-top: 5px;
}

.image-menu {
  width: 100%;
  height: 100%;
}

.image-menu:hover {
  -webkit-transition: transform 0.5s ease-in-out;
  transform: scale(1.10);
}

.nav-menu .link-container {
  margin: 0 15px 0 15px;
  cursor: pointer;
}

.nav-links {
  text-decoration: none;
}

.nav-menu a:hover {
  width: 100%;
  text-shadow: 0px 0px 20px white;
  visibility: visible;
}

.menu-icon {
  display: none;
}

.socials {
  position: absolute;
  text-align: left;
  right: 25px;
  top: 15px;
}

.socials img {
  cursor: pointer;
  width: 50px;
}

.socials img:hover {
  -webkit-transition: transform 0.5s ease-in-out;
  transform: scale(1.10);
}

@media screen and (max-width: 1200px) {
  .navbar-container {
    top: 0px;
    width: 100%;
    z-index: 15;
    height: 90px;
    position: fixed;
    backdrop-filter: none;
  }

  .navbar-container.active {
    mask-image: none;
    height: 75px;
  }

  .navbar {
    top: 0px;
    width: 100%;
    z-index: 9;
    height: 65px;
    position: fixed;
    background-color: black;
  }

  .logo {
    position: absolute;
    width: 165px;
    height: 31px;
    top: 20px;
    left: 25px;
  }

  .nav-menu {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 48.5px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    height: 100vh;
    list-style: none;
    font-weight: bold;
    font-family: "Roboto";
  }

  .nav-menu.active {
    position: fixed;
    left: 0;
    z-index: 10;
    opacity: 1;
    transition: all 1s ease;
    height: 100vh;
    background-color: black;
  }

  .nav-item {
    padding: 15px 0 15px 0;
  }

  .nav-links {
    line-height: 60px;
    width: 100%;
    display: flex;
    color: white;
    align-items: center;
    text-decoration: none;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: -7px;
    right: 25px;
    line-height: 80px;
    font-size: 2rem;
    color: white;
  }

  .socials {
    position: absolute;
    width: 95%;
    top: 450px;
    text-align: center;
    z-index: 10;
  }

  .socials.active {
    animation: fadeIn 1.5s ease-in both;
  }

  .socials img {
    width: 45px;
  }

  .socials-active img {
    width: 45px;
  }

}
