.sliding-nft-promo-image {
  background: url("./assets/sliding_galery.png") repeat-y;
  background-size: contain;
  height: 10000px;
  width: 300px;
  animation: bg 200s linear infinite;
}


.sliding-nft-container {
  margin-top: 25px;
  width: 300px;
  height: 300px;
  font-family: "Roboto";
  overflow: hidden;
}

.flex-nft-mint {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.nft-count-select {
  font-family: "Roboto";
  margin: 20px;
  padding: 10px;
  width: 300px !important;
  border-radius: 10px;
  background-color: black;
  color: white;
  border: 1px solid grey;
}

.nft-count-select option {
  width: 300px !important;
  border-radius: 10px;
}

.nft-count-select::after {
  content: "aasd";
}

.nft-info {
  display: flex;
  justify-content: space-between;
  width: 310px;
  color: white;
  font-size: 15px;
}

.right-nft-info {
  padding-right: 5px;
}

.left-nft-info {
  padding-left: 5px;
}

@-webkit-keyframes bg {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
