@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

/* utility */

.ul-utility {
  margin: 0;
  list-style: none;
  @media only screen and (min-width: 1200px) {
    margin-left: 10px;
  }
}

/* .image-promo {
  animation: changeBg 12s infinite;
  background-position: center center;
  background-repeat: no-repeat;
} */

@keyframes changeBg {
  0%,
  100% {
    background-size: contain;
    background-image: url("./pages/assets/IMG_3601.jpg");
    width: 100%;
    height: 130%;
  }
  20% {
    background-image: url("./pages/assets/IMG_3602.jpg");
    background-size: contain;
    width: 100%;
    height: 130%;
  }
  40% {
    background-image: url("./pages/assets/IMG_3603.jpg");
    background-size: contain;
    width: 100%;
    height: 130%;
  }
  60% {
    background-image: url("./pages/assets/IMG_3604.jpg");
    background-size: contain;
    width: 100%;
    height: 130%;
  }
  80% {
    background-image: url("./pages/assets/IMG_3605.jpg");
    background-size: contain;
    width: 100%;
    height: 130%;
  }
}

.image-promo-mobile {
  animation: changeBgP 10s infinite;
  background-size: 450px 460px;
  background-position: center center;
  background-repeat: no-repeat;
  width: 90%;
  height: 120%;
  background-image: url("./pages/assets/IMG_3601_M.jpg");
}

@keyframes changeBgP {
  20% {
    background-image: url("./pages/assets/IMG_3602_M.jpg");
  }
  80% {
    background-image: url("./pages/assets/IMG_3603_M.jpg");
  }
  100% {
    background-image: url("./pages/assets/IMG_3604_M.jpg");
  }
}

.utility-text-head {
  text-align: left;
  font-family: "Roboto";
  width: 90%;
  margin: 0 auto;
  padding-bottom: 10px;
  font-size: 17px;
  @media only screen and (max-width: 1200px) {
    text-align: center;
  }
  animation: fade 1s ease-in both;
  opacity: 0;
}

.mint-container {
  padding-bottom: 15px;
  @media only screen and (max-width: 1200px) {
    padding-bottom: 45px;
  }
}

.social-container-head {
  padding-bottom: 15px;
}

.socials-container {
  padding-bottom: 15px;
  text-align: center;
  font-family: "Roboto";
  @media only screen and (max-width: 1200px) {
    padding-bottom: 45px;
    padding-top: 45px;
  }
}

.social-container-objects {
  padding: 10px;
  display: inline-block;
  @media only screen and (max-width: 960px) {
    display: block;
  }
}

.social-container-objects-images {
  cursor: pointer;
  width: 250px;
  padding-top: 15px;
}

.social-container-objects-images:hover {
  -webkit-filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
  filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
}

.social-container-text {
  font-size: 15px;
  display: block;
}

.social-container-header-text {
  font-size: 20px;
  display: block;
}

.li-image {
  position: relative;
  width: 20px;
  top: 5px;
  padding-right: 15px;
  @media only screen and (max-width: 560px) {
    width: 20px;
    top: 5px;
    padding-right: 25px;
  }
}

.li-utility {
  font-family: "Roboto";
  line-height: 35px;
  font-size: 17px;
  cursor: pointer;
}

.utility-header {
  padding-bottom: 45px !important;

  @media only screen and (max-width: 960px) {
    padding-top: 35px !important;
  }
}

.loader-header {
  width: 120px;
}

.video-container {
  text-align: center;
  display: inline-block;
  width: 45%;
  padding-right: 5%;
  float: right;
  @media only screen and (max-width: 1200px) {
    text-align: center;
    align-items: center;
    display: contents;
    width: 100%;
  }
}

.utility-text {
  display: inline-block;
  width: 40%;
  text-align: left;
  padding-left: 10%;
  float: left;
  @media only screen and (max-width: 1200px) {
    display: block;
    width: 80%;
    padding-bottom: 45px;
  }
  @media only screen and (max-width: 960px) {
    padding-left: 13%;
  }
  @media only screen and (max-width: 560px) {
    width: 100%;
    padding-left: 0;
  }
}

.wrapper-section {
  padding-top: 5%;
}

.utility {
  padding-top: 10%;
  padding-bottom: 30px;
  height: 800px;
  margin: auto 0;
  text-align: center;
  @media only screen and (max-width: 1200px) {
    height: 1200px;
  }
}

/* X utility */

/* FOOTER */

.footer-container {
  height: auto;
  margin: auto 0;
  text-align: center;
}

/* X FOOTER */

/* Artist */

.artist-img-name {
  padding-top: 10px;
  position: relative;
  left: -12px;
  width: 200px !important;
  @media (max-width: 1100px) {
    display: block;
    margin: 0 auto;
    left: 5px;
  }
}

/* Predelat na stejny format nadpisů */

.artist-header {
  display: inline-block;

  width: 260px !important;
}

.artist-icons {
  display: inline-block;
  width: 50px !important;
  margin: 0 auto;
  padding-top: 10px;
  padding-left: 5px;
  cursor: pointer;
}

.artist {
  padding-top: 50px;
}

/* X artist */
body {
  margin: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: black;
  font-family: "Roboto" !important;
}

span {
  color: white;
}

.home {
  max-height: 1300px;
  padding-bottom: 20px;
}

.clippy {
  background-image: url("./assets/background1.png");
  clip-path: polygon(
    25% 6%,
    60% 0,
    100% 3%,
    100% 93%,
    74% 98%,
    46% 96%,
    23% 100%,
    0 97%,
    0 0
  );

  @media only screen and (max-width: 1200px) {
    clip-path: polygon(
      0 1%,
      25% 2%,
      61% 1%,
      85% 3%,
      100% 0,
      100% 15%,
      100% 98%,
      83% 99%,
      51% 100%,
      29% 98%,
      13% 100%,
      0 98%
    );
  }
}

.clippy2 {
  background-image: url("./assets/background1.png");
  clip-path: polygon(32% 0, 100% 5%, 100% 95%, 90% 98%, 25% 100%, 0 96%, 0 6%);

  @media only screen and (max-width: 1000px) {
    clip-path: polygon(
      0 1%,
      26% 0,
      50% 1%,
      75% 0,
      100% 1%,
      100% 15%,
      100% 98%,
      78% 100%,
      55% 98%,
      30% 100%,
      10% 99%,
      0 100%
    );
  }
}

a {
  text-decoration: none;
}

.section-header {
  display: block;
  margin: 0 auto;
  padding-bottom: 15px;
  @media only screen and (max-width: 960px) {
    width: 450px;
  }
  @media only screen and (max-width: 560px) {
    width: 300px;
  }
}

.slider-images {
  width: 350px;
  height: 350px;
}

.team-header {
  padding-top: 15px;
  @media only screen and (max-width: 960px) {
    width: 560px;
  }
  @media only screen and (max-width: 560px) {
    width: 360px;
  }
}

p {
  color: white;
}

/* loading screen */

.dot {
  animation: MoveUpDown 1.5s linear infinite;
}

.dot-1 {
  animation-delay: 0.2s;
}

.dot-2 {
  animation-delay: 0.4s;
}

.dot-3 {
  animation-delay: 0.8s;
}
/* X loading screen */

.roadmap {
  height: auto;
  margin: auto 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10%;
  padding-top: 5%;
}

.none {
  display: none;
}

.team-container {
  padding-top: 5%;
  height: auto;
  margin: auto 0;
  text-align: center;
  padding-bottom: 10%;
  @media only screen and (max-width: 1600px) {
    padding-top: 10%;
    padding-bottom: 20%;
  }
  @media only screen and (max-width: 800px) {
  }
  @media only screen and (max-width: 600px) {
    padding-top: 20%;
  }
}

.text-promo {
  position: relative;
  top: -40px;
  z-index: 2;
  margin: auto 0;
  text-align: center;
  max-height: 900px;
  animation: fade 1s ease-in both;
  opacity: 0;
  @media only screen and (max-width: 960px) {
    top: -50px;
  }
}

.header-collection {
  position: relative;
  display: block;
  top: 35px;
  width: 620px;
  @media only screen and (max-width: 640px) {
    width: 560px;
  }
  @media only screen and (max-width: 560px) {
    width: 400px;
  }
  @media only screen and (max-width: 390px) {
    width: 350px;
  }
}

.by-cyberink-notation-header {
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 2;
  top: -30px;
  width: 300px;
  @media only screen and (max-width: 640px) {
    top: 0px;
  }
  @media only screen and (max-width: 560px) {
    width: 200px;
    top: 0px;
  }
  @media only screen and (max-width: 390px) {
    width: 150px;
    top: 0px;
  }
}

.animate {
  transition: opacity 2s ease-out;
  opacity: 0;

  &.reveal {
    opacity: 1;
  }
}
.button-promo {
  position: absolute;
  top: 90%;
  left: 50%;
  justify-content: center;
  justify-items: center;
  display: flex;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.mint-button {
  position: relative;
  filter: none;
  width: 50%;
  cursor: pointer;
  padding-top: 25px;
  @media only screen and (max-width: 960px) {
    width: 80%;
  }
}

.mint-button:hover {
  -webkit-filter: drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.3));
  filter: drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.3));
}

.opensea-embed {
  display: block;
  position: relative;
  @media only screen and (max-width: 960px) {
    top: 65px;
  }
  @media only screen and (max-width: 400px) {
    top: 15px;
  }
}

.opensea-embed img {
  width: 220px;
  border-radius: 5px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
}

.faq-container {
  padding-top: 5%;
  height: auto;
  margin: auto 0;
  text-align: center;
}

@keyframes fillColor {
  from {
  }
  to {
    background-position: left;
  }
}

.intro-header {
  background: linear-gradient(
      to left,
      black 50%,
      rgb(181, 112, 233) 55%,
      rgba(123, 117, 215, 1) 65%,
      rgba(79, 151, 197, 1) 75%,
      rgb(19, 197, 172, 1) 85%
    )
    right;
  background-size: 200%;
  animation: fillColor 4s ease-out;
  width: 950px;
  height: 190px;
  @media only screen and (max-width: 1200px) {
    width: 610px;
    height: 125px;
  }
  @media only screen and (max-width: 860px) {
    width: 560px;
    height: 115px;
  }
  @media only screen and (max-width: 560px) {
    width: 455px;
    height: 95px;
  }
  @media only screen and (max-width: 390px) {
    width: 352px;
    height: 75px;
  }
}

.hammer-animation {
  padding-top: 10px;
  padding-bottom: 10px;
}

.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  z-index: 10;
}

.todo {
  color: white;
  z-index: 2;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  background-color: black;
}
::-webkit-scrollbar {
  width: 12px;
  background-color: black;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #292929;
  background-image: -webkit-linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.4) 50%,
    transparent,
    transparent
  );
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -30%, 0);
  }
}
