.faq {
  position: relative;
  margin-top: 40px;
  margin: 0 auto;
  @media only screen and (max-width: 1300px) {
    width: 95%;
  }
}
summary {
  font-size: 17px;
  /* backdrop-filter: blur(100px) contrast(70%);
  -webkit-backdrop-filter: blur(100px) contrast(70%); */
  background-color: rgba(78, 76, 76, 0.687);
  color: white;
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;

  border-radius: 0.5rem;
  text-align: left;
  padding-right: 30px;
  cursor: pointer;
  position: relative;
  font-family: "Roboto";
}
.faq__content {
  /* backdrop-filter: blur(100px) contrast(70%);
  -webkit-backdrop-filter: blur(100px) contrast(70%); */
  background-color: rgba(78, 76, 76, 0.687);
  font-size: 17px;
  color: white;
  border-radius: 0.5rem;
  font-family: "Roboto";
  margin: 15px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  text-align: left;
}
details > summary::after {
  position: absolute;
  content: "↟";
  transform: scale(1.4);
  color: "grey";

  right: 20px;
}
details[open] > summary::after {
  position: absolute;
  content: "↡";
  transform: scale(1.4);
  color: "grey";
  right: 20px;
}
details > summary::-webkit-details-marker {
  display: none;
}
details[open] summary ~ * {
  animation: sweep 0.5s ease-in-out;
}
@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

.back_to_utilities {
  text-align: right !important;
  width: 100% !important;
}

.back_to_utilities_text {
  background-color: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 15px 5px 10px;
  border-radius: 60px 45px 30px 15px/15px 30px 45px 60px;
}

.up_sign_faq {
  display: inline-block;
  color: black;
  padding-left: 5px;
  transform: scale(1.4);
}
