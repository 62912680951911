.timeline {
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin: 0 auto;
  font-family: "Roboto";

  &__event {
    background-color: rgba(78, 76, 76, 0.687);

    margin-bottom: 20px;
    position: relative;
    display: flex;
    margin: 35px 0;
    border-radius: 8px;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
      0 18px 36px -18px rgba(0, 0, 0, 0.3),
      0 -12px 36px -8px rgba(0, 0, 0, 0.025);

    &__title {
      font-size: 20px;
      line-height: 1.4;
      font-weight: bold;
      text-transform: uppercase;
      font-weight: 600;
      color: white;
      letter-spacing: 1.5px;
    }
    &__content {
      width: 100%;
      padding: 20px;
      @media (max-width: 786px) {
        padding: 7px;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 95%;
      }
    }
    &__icon {
      border-radius: 8px 0 0 8px;
      background: #9251ac;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 40%;
      font-size: 2rem;
      color: #9251ac;
      padding: 20px;
    }
    &__description {
      flex-basis: 60%;
      text-align: center;
    }

    &:before {
      content: url("./assets/not_done.png");
      transform: scale(0.6);
      width: 30px;
      height: 30px;
      top: -70px;
      left: -50px;
      position: absolute;
      display: inline-block;
      @media (max-width: 786px) {
        transform: scale(0.5);
        top: -65px;
        left: -30px;
      }
    }
    &--type2 {
      &:before {
        content: url("./assets/done.png");
      }
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
  }
  .timeline {
    width: 85%;
  }

  .timeline__event__icon {
    border-radius: 4px 4px 0 0;
  }
}

.percent__roadmap {
  position: absolute;
  right: -15px;
  font-size: 1.7rem;
  @media (max-width: 786px) {
    bottom: -15px;
  }
}
